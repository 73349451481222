import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageConclusion from '../common/src/assets/image/charity/specter/kapitola_zaver_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Záver: Ako duch komunizmu vládne nášmu svetu | Ako duch komunizmu vládne nášmu svetu"
           description="Záver: Ako duch komunizmu vládne nášmu svetu"
           image={imageConclusion}/>
        <h1>Záver: Ako duch komunizmu vládne nášmu svetu</h1>
        <p>V priebehu dlhej histórie zažilo ľudstvo obdobie nádhery a slávy, prežilo však tiež nespočetné množstvo tragédií a katastrof. Keď sa obzrieme späť, zistíme, že predpokladom poctivej vlády, ekonomického rozmachu, prekvitajúcej kultúry a národnej prosperity je morálna bezúhonnosť. Naopak, mravná skazenosť je predzvesťou pádu národov a zániku celých civilizácií.</p>
        <p>Dnešné ľudstvo je na vrchole hmotného bohatstva, i tak však čelí nebývalým výzvam, ktoré vyvolala pohroma komunizmu. Konečným cieľom komunistickej ideológie nie je vytvoriť nebo na zemi, ale zničiť ľudstvo. Komunizmus má povahu zlého ducha, ktorý bol vytvorený nenávisťou, skazenosťou a ďalšími živelnými silami vo vesmíre. Z nenávisti zabil viac ako sto miliónov ľudí, pošliapal niekoľko tisíc rokov nádhernej civilizácie a skazil ľudskú morálku.</p>
        <p>Komunistický duch zostavil plány na podkopanie Východu i Západu a v rôznych krajinách na to prijal rôzne stratégie. Na Východe nemilosrdne zabíjal a prinútil ľudí prijať ateizmus. Na Západe sa komunizmus vydal odlišnou cestou: infiltroval spoločnosť skrytým spôsobom a nenápadne primäl ľudí k tomu, aby opustili svoju vieru a tradičné morálne hodnoty.</p>
        <p>Komunizmus pomocou svojich režimov a organizácií, „spolucestujúcich“, spolupáchateľov a ďalších agentov nazhromaždil negatívne prvky, prítomné v ľudskom svete a získal ohromnú moc. S touto silou podkopal a ovládol všetky aspekty spoločnosti, vrátane politiky, ekonomiky, práva, vzdelávania, médií, umenia a kultúry. Dnešné ľudstvo sa tak ocitlo v ťaživej situácii.</p>
        <p>Ak sa pozrieme na posledné dve storočia spoločenského vývoja, dôvody víťazstva komunizmu sú jasné. Keď sa ľudia oddajú materiálnym pôžitkom, ktoré priniesol technologický pokrok a umožnia šírenie ateizmu, odmietajú Božie milosrdenstvo a odovzdávajú sa zlu. Keďže ľudstvo sa dnes z veľkej časti odklonilo od tradícií ustanovených nebesami, väčšina ľudí sa necháva ľahko oklamať komunizmom a jeho nespočetnými ideologickými obmenami, ako sú socializmus, liberalizmus a progresivizmus.</p>
        <p>Tradičná kultúra ukazuje ľudom cestu, ako si udržať svoju morálku a získať spásu v záverečnej epoche. Avšak s tým, ako sa začalo útočiť na tradičnú kultúru a boli odvrhnuté základné morálne pravdy, prerušilo sa i spojenie medzi človekom a nebesami. Človek už nedokáže pochopiť pokyny nebies a zlo získalo prevahu, čo spôsobilo zmätok v ľudskom svete. Keď ľudská morálka poklesne pod úroveň základných štandardov, ktoré boli pre človeka stanovené, nebesá budú musieť ľudské bytosti neochotne opustiť a diabol ich povedie do priepasti večného zatratenia.</p>
        <p>Po dosiahnutí extrému sa však situácia zaručene obráti. V ľudskej ríši platí večný princíp, že zlo nikdy nemôže poraziť spravodlivosť. Momentálne víťazstvo komunizmu je iba dočasný jav, ktorý priniesol diabol, aby zastrašoval ľudí svojou iluzórnou silou a zradnými pokušeniami. Avšak napriek svojej nedokonalosti má v sebe človek vrodenú láskavosť, cnosť a morálnu odvahu, ktoré sa upevňovali a odovzdávali po celé tisícročia. V tom spočíva naša nádej.</p>
        <p>Globálne udalosti sa vyvíjajú neuveriteľne rýchlym tempom. Spravodlivé elementy sú na vzostupe a ľudia na celom svete sa prebúdzajú.</p>
        <p>Milióny ľudí v Číne pokojne vzdorujú tyranskej vláde Čínskej komunistickej strany tým, že vytrvávajú vo svojej viere a zachovávajú si svoju morálku. Viac ako 370 miliónov Číňanov, inšpirovaných sériou <em>Deviatich komentárov ku komunistickej strane</em>, odvážne pretrhlo svoje väzby s ČKS a jej pridruženými organizáciami prostredníctvom hnutia „Tuidang“ („vystúpenia zo strany“). Stále viac jednotlivcov robí úprimné rozhodnutie vymaniť sa z pút komunizmu. Rozpad komunistickej strany už prebieha.</p>
        <p>Koniec komunistickej strany je vecou nebeského usporiadania. Ak čínski vodcovia podniknú kroky na rozpustenie strany, budú im poskytnuté všetky podmienky pre hladký prechod. V budúcnosti získajú skutočnú autoritu, ktorú im poskytnú nebesia. Pokiaľ budú tvrdohlavo vzdorovať rozpadu strany, osud strany sa stane aj ich osudom a postihnú ich katastrofy spojené s konečným pádom strany.</p>
        <p>Svet prežíva obnovu tradičnej kultúry a morálky v súlade s univerzálnymi hodnotami pravdivosti, súcitu a tolerancie. V popredí tejto renesancie je súbor Shen Yun Performing Arts, ktorý v rámci každoročného turné cestuje po piatich svetadieloch. Prostredníctvom umeleckej formy klasického čínskeho tanca prináša Shen Yun univerzálne hodnoty divákom po celom svete.</p>
        <p>Západ teraz začal rozpoznávať komunistickú infiltráciu a jeho rozvrátenie tradičnej kultúry, ku ktorému začalo dochádzať už v minulom storočí. Očista od komunistických prvkov a odchýlenej modernej kultúry začala v mnohých oblastiach, vrátane práva, vzdelávania, vládnej administratívy a medzinárodných vzťahov. Vlády sú ostražitejšie voči komunistickým režimom a ich prvkom, čo značne obmedzuje vplyv komunizmu na globálnej scéne.</p>
        <p>Komunizmus nie je nepriateľ, ktorého možno poraziť vojenskou silou. Aby sme vyslobodili svet z jeho zovretia, musíme začať tým, že očistíme svoje vlastné vnútro. Li Chung-č’, zakladateľ Falun Gongu, napísal vo svojom článku „Utíšte vonkajšie kultiváciou vnútorného“:</p>
        <p>Ak si ľudia nevážia cnosť, svet bude vo veľkom neporiadku a mimo kontroly. Každý sa každému stane nepriateľom a bude žiť bez šťastia. Keď budú žiť bez šťastia, nebudú sa obávať smrti. Lao-c’ povedal: „Ak sa ľudia neobávajú smrti, načo je dobré strašiť ich ňou?“ Toto je veľké hroziace nebezpečenstvo. Ľudia dúfajú v pokojný svet. Ak sa teraz vytvorí nadmerné množstvo zákonov a nariadení na zabezpečenie pokoja, skončí to opačným výsledkom. Aby sa tento problém vyriešil, musí sa po celom svete kultivovať cnosť – iba takto možno tento problém od základu vyriešiť. Ak sú úradníci nesebeckí, štát nebude skorumpovaný. Ak si obyvateľstvo váži sebakultiváciu a pestovanie cnosti a keď úradníci aj občania rovnako uplatňujú sebaovládanie vo svojich mysliach, celý národ bude stabilný a podporovaný obyvateľstvom. Ak bude pevný a stabilný, národ prirodzene zastraší vonkajších nepriateľov a pokoj a mier zavládne pod nebesami. To je práca mudrca.<a href="#_edn1" id="_ednref1">[1]</a></p>
        <p>Milosrdný Stvoriteľ vždy bdel nad ľudstvom. Keď sa človek obráti proti nebeským princípom, nastanú katastrofy. Ľudstvo môže byť zachránené iba keď sa vráti k svojmu dedičstvu, ktoré mu zverili nebesá. Pokiaľ dokážeme prehliadnuť diablov klam, udržíme si srdce skutočného súcitu, budeme nasledovať štandardy, ktoré nebesia pre človeka ustanovili, oživíme tradičné hodnoty a navrátime sa k tradičnej kultúre, nebesá oslobodia ľudstvo od zla. To, či sa ľudská spoločnosť touto cestou vydá alebo nie, je voľbou, pred ktorou dnes stojí každý z nás.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> Li Chung-č’, „Utíšte vonkajšie kultiváciou vnútorného“ v&nbsp;Základoch pre ďalší pokrok, 5. januára 1996, <a href="https://sk.falundafa.org/knihy/jingwen/1995-2001/jjyz_sk.pdf">https://sk.falundafa.org/knihy/jingwen/1995-2001/jjyz_sk.pdf</a></p>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


